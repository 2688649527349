'use strict';

import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

require('../../node_modules/photoswipe/dist/photoswipe.css');
require('../../node_modules/photoswipe/dist/default-skin/default-skin.css');

export default class ImageGallery {

  /**
   * Image gallery constructor.
   * @param element
   */
  constructor(element) {
    this.element = element;
    this.popup = document.querySelector('.pswp');
    this.links = element.querySelectorAll('.gallery__link');
    this.items = this.getImages();

    const popup = this.popup;
    const items = this.items;
    const links = this.links;

    const figures = element.querySelectorAll('.gallery__item');
    figures.forEach(figure => {
      figure.addEventListener('click', function(event) {
        event.preventDefault();
        let gallery = new PhotoSwipe(popup, PhotoSwipeUI_Default, items, {
          bgOpacity: 0.9,
          showHideOpacity: true,
          index: parseInt(event.currentTarget.dataset.index),
          getThumbBoundsFn: function(index) {
            const thumbnail = links[index].querySelector('img');
            const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
            const rect = thumbnail.getBoundingClientRect();

            return {
              x: rect.left,
              y: rect.top + pageYScroll,
              w: rect.width,
            };
          },
        });

        gallery.init();
        gallery = null;
      });
    });
    // $(element).find('figure').unbind('click').bind('click', );
  }

  /**
   * Extract all images from the links.
   * @returns {Array}
   */
  getImages() {
    const storage = [];
    this.links.forEach(item => {
      const size = item.getAttribute('data-size').split('x');
      storage.push({
        w: size[0],
        h: size[1],
        src: item.href,
      });
    });
    return storage;
  }

}
