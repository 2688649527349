import Rellax from 'rellax';
import ImageGallery from '../blocks/gallery/gallery';

// Import SCSS assets:
require('./scss/site.scss');

/**
 * Scroll window to a certain target.
 * @param e
 */
const scroll_to = e => {
  e.preventDefault();
  const scroll_target = e.target.dataset.scroll;
  const scroll_element = document.querySelector(scroll_target);
  console.log(scroll_element);

  window.scrollTo({
    top: scroll_element.offsetTop,
    behavior: 'smooth',
  });
};

const galleries = document.querySelectorAll('.gallery');
[...galleries].forEach(element => new ImageGallery(element));

const click_anchors = document.querySelectorAll('[data-scroll]');
[...click_anchors].forEach(element => element.addEventListener('click', scroll_to));

let scroll_offset = null;
const rellax_instances = [];
const rellaxin = document.querySelectorAll('.rellax');
rellaxin.forEach((element, index) => {
  rellax_instances[index] = new Rellax(element, {
    relativeToWrapper: true,
    wrapper: element.parentElement,
  });
});

/**
 * Loop through each parallax instance and refresh section.
 */
const refresh_rellax = () => {
  rellax_instances.forEach(instance => instance.refresh());
};

/**
 * Method to execute on every animation frame.
 */
const frame_callback = function() {
  const current_position = document.documentElement.scrollTop;
  if (current_position !== scroll_offset) {
    scroll_offset = current_position;
    refresh_rellax();
  }
  window.requestAnimationFrame(frame_callback);
};

refresh_rellax();
window.requestAnimationFrame(frame_callback);

if (module.hot) {
  module.hot.accept();
}
